import { Table } from "flowbite-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/pricelist.css';

export default function PriceList(props) {
    return (
        <>
            <section id="arlista">
                <div className="flex flex-col gap-10 items-center PriceList">
                    <div className="transition_bottom"></div>
                    <h2>Árlista</h2>
                    <div className="responsive_table">
                        <Table>
                            {/* HEADER */}
                            <Table.Head>
                                <Table.HeadCell className="">Szolgáltatások</Table.HeadCell>
                                <Table.HeadCell className="pricelistHeaders">&nbsp;Rövid</Table.HeadCell>
                                <Table.HeadCell className="pricelistHeaders">Félhosszú</Table.HeadCell>
                                <Table.HeadCell className="pricelistHeaders">Hosszú</Table.HeadCell>
                                <Table.HeadCell className="pricelistHeaders">Extra Hosszú</Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">

                                {/* MEN */}
                                <Table.Row>
                                    <Table.Cell className="pricelistTypes py-0">Férfi</Table.Cell>
                                    <Table.Cell className="pricelistTypes" colSpan={4}></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Hajvágás</Table.Cell>
                                    <Table.Cell className="pricelistPrices">3 800 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Hajvágás mosással</Table.Cell>
                                    <Table.Cell className="pricelistPrices">4 200 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Gépi vágás egyhosszra</Table.Cell>
                                    <Table.Cell className="pricelistPrices">1 600 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                </Table.Row>

                                {/* Children */}
                                <Table.Row>
                                    <Table.Cell className="pricelistTypes">Gyerek</Table.Cell>
                                    <Table.Cell className="pricelistTypes" colSpan={4}></Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Gyerek hajvágás 0-6 év</Table.Cell>
                                    <Table.Cell className="pricelistPrices">2 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                </Table.Row>

                                {/* WOMEN */}
                                <Table.Row>
                                    <Table.Cell className="pricelistTypes">Női</Table.Cell>
                                    <Table.Cell className="pricelistTypes" colSpan={4}></Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Női mosás, vágás, szárítás</Table.Cell>
                                    <Table.Cell className="pricelistPrices">6 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">7 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">8 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">9 000 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Női mosás, szárítás</Table.Cell>
                                    <Table.Cell className="pricelistPrices">4 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">6 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">7 000 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Festés</Table.Cell>
                                    <Table.Cell className="pricelistPrices">160 Ft/g</Table.Cell>
                                    <Table.Cell className="pricelistPrices">160 Ft/g</Table.Cell>
                                    <Table.Cell className="pricelistPrices">160 Ft/g</Table.Cell>
                                    <Table.Cell className="pricelistPrices">160 Ft/g</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Festés hozott anyaggal</Table.Cell>
                                    <Table.Cell className="pricelistPrices">4 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">6 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">7 500 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Tő szőkítés</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 000 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Teljes szőkítés</Table.Cell>
                                    <Table.Cell className="pricelistPrices">6 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">7 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">8 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">10 500 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Melír fólia/db</Table.Cell>
                                    <Table.Cell className="pricelistPrices">250 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">300 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">350 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">400 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Ombre/Balayage</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">30 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">34 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">38 000 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Hajvasalás</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">1 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">2 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">2 500 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Göndörítés</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">2 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">3 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">3 500 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Feltűzés</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">3 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">4 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 000 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Konty</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">6 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">7 500 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Fonás tincsenként fejbőrhöz</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">1 100 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">1 100 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">1 100 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Parketta fonás egész haj</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">2 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">3 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">3 500 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Lisaplex regeneráló kezelés</Table.Cell>
                                    <Table.Cell className="pricelistPrices">4 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">5 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">6 500 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">7 500 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Cocochoco tartós egyenesítés</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">28 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">37 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">40 000 Ft</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell className="pricelistServices">Cocochoco botox</Table.Cell>
                                    <Table.Cell className="pricelistPrices">-</Table.Cell>
                                    <Table.Cell className="pricelistPrices">27 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">36 000 Ft</Table.Cell>
                                    <Table.Cell className="pricelistPrices">39 000 Ft</Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </Table>
                    </div>

                </div>
            </section>
        </>
    );
}